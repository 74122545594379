@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.accordion {
  background: transparent;
  scroll-margin: fn.spacing(10);
  border-bottom: 1px solid var.$color-neutral-700;

  &:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.accordion::before {
  position: inherit;
  background-color: transparent;
  content: none;
}

.accordion-summary {
  padding: 16px 0;
}

.accordion-summary-text {
  padding-right: 16px;
}

.accordion-summary .accordion-summary-text {
  margin-bottom: 0;
  margin-top: 0;
}

.accordion-detail {
  padding-bottom: 8px;
}
